/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const Community = () => {
  return (
    <div  css = {css`
    margin-top : 84px;
`}>
      <h1>Community</h1>
    </div>
  );
};

export default Community;
