/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const Partner = () => {
  return (
    <div css = {css`
    margin-top : 84px;
`}>
      <h1>Partner</h1>
    </div>
  );
};

export default Partner;
