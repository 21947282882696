/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "styled-components";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { EffectCoverflow, Pagination } from "swiper";

const Home = () => {
  return (
    /* Main Frame */
    <div>
      <Container>
        <HomeTitle>
          완전 무료로 써내려가는 우리의 결혼이야기 <br />
          WEDDING PAGE
        </HomeTitle>
        <div
          css={css`
            margin-top: 17px;
            font-size: 20px;
          `}
        >
          너와 내가 같이하는, 우리의 결혼준비
        </div>
        <div
          css={css`
            margin-top: 50px;
          `}
        >
          <KakaoButton>
            <img
              css={css`
                margin: 10px;
              `}
              src="/img/kakaoIcon.png"
            />
            카카오계정으로 시작하기
          </KakaoButton>
        </div>
      </Container>
      <img
        css={css`
          margin-top: 100px;
          width: 100%;
          height: 100%;
        `}
        src="/img/Main.png"
        alt=""
      ></img>

      <div
        css={css`
          margin-top: 50px;
          white-space: nowrap;
          font-size: 40px;
          text-align: center;
        `}
      >
        파트너와 함께 계획하며, 결혼을 더 가치 있게
      </div>

      <div
        css={css`
          margin-top: 50px;
          border-radius: 10px;
          background: #ffffff;
          font-size: 40px;
          text-align: center;
        `}
      >
        모든 결혼 일정을 한눈에
      </div>
      <div css = {css`
        margin: 80px;
      `}>
       <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
        <SwiperSlide>Slide 1</SwiperSlide>
        <SwiperSlide>Slide 2</SwiperSlide>
        <SwiperSlide>Slide 3</SwiperSlide>
        <SwiperSlide>Slide 4</SwiperSlide>
        <SwiperSlide>Slide 5</SwiperSlide>
        <SwiperSlide>Slide 6</SwiperSlide>
        <SwiperSlide>Slide 7</SwiperSlide>
        <SwiperSlide>Slide 8</SwiperSlide>
        <SwiperSlide>Slide 9</SwiperSlide>
      </Swiper>
      </div>
    </div>
  );
};

const HomeTitle = styled.div`
  font-size: 40px;
  font-weight: 700;
  white-space: nowrap;
  text-align: center;
  line-height: 60px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10% 0;
`;

const KakaoButton = styled.button`
  display: flex;
  height: 60px;
  width: 100%;
  background: #fff;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  border-radius: 4px;
  white-space: nowrap;
`;

const PlannerItem = styled.div`
  display: flex;
  height: 200px;
  width: 80%;
  background: #D9D9D9;
  align-items: center;
`

export default Home;
