/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const Account = () => {
  return (
    <div
      css={css`
        margin-top: 84px;
      `}
    >
      <h1>Account</h1>
    </div>
  );
};

export default Account;
