import styled from "@emotion/styled";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const Footer = () => {
  return (
    <FooterStyle>
      <LogoContainer>
        <FooterLogo>
          <Link to="/"> WEDDING PAGE</Link>
        </FooterLogo>
        <div
          css={css`
            display: flex;
            padding-top: 1rem;
          `} 
         > 
          <LogoItem><Link to = "/terms">이용약관</Link></LogoItem>
          <LogoItem><Link to = "/privacypolicy">개인정보처리방침</Link></LogoItem>
          <LogoItem><Link to = "/youthprotectionpolicy" >청소년보호정책</Link></LogoItem>
          <LogoItem><Link to = "/checkbusinessinfomation" >사업자정보확인</Link></LogoItem>
        </div>
        <div
          css={css`
            font-size: 12px;
            padding-top: 1rem;
            color : #888888;
          `}
        >
          Copyright LIFEPAGE Corp. all rights reserved
        </div>
      </LogoContainer>
      <ItemContainer>
        <div
          className="row"
          css={css`
            margin: 0 auto;
          `}
        >
          <div
            className="col-md-6"
            css={css`
              display: flex;
            `}
          >
            <Item>
              <Link to="/plan">일정</Link>
            </Item>
            <Item>
              <Link to="/partner">파트너</Link>
            </Item>
            <Item>
              <Link to="/account">가계부</Link>
            </Item>
            <Item>
              <Link to="/gellery">갤러리</Link>
            </Item>
          </div>
          <div
            className="col-md-6"
            css={css`
              display: flex;
              padding-top: 2rem;
            `}
          >
            <Item>커뮤니티</Item>
            <Item>알림설정</Item>
            <Item>공지사항</Item>
            <Item>고객문의</Item>
          </div>
        </div>
      </ItemContainer>
    </FooterStyle>
  );
};

//#region 스타일 컴포넌트
const FooterLogo = styled.h1`
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  color: #000;
`;


const FooterStyle = styled.footer`
  padding : 60px 0;
  font-family: "Noto Sans KR";
  display: flex;
  width: 100%;
  justify-content: center;
  background: #f7f9fa;
`;

const LogoContainer = styled.div`
  background: #f7f9fa;
`;

const LogoItem = styled.div`
  color: #888888;
  padding-right: 10px;
  border-right-width  : 1px;
`

const ItemContainer = styled.div`
  padding-left: 10rem;
  background: #f7f9fa;
`;

const Item = styled.div`
  text-align: left;
  width : 140px;
`;

//#endregion

export default Footer;
