import { BrowserRouter } from "react-router-dom";
import "./App.css"; /* CSS Import하고 아래 클래스 네임을 넣고 호출 */
import "./assets/css/reset.css";
import Layout from "./components/Layout";
import Login from "./components/Lock/Login";

/*function App 함수는 메인 함수와 비슷한것 같음*/
function App() {
  return (
    <BrowserRouter>
      <Layout/>
    </BrowserRouter>
  );
}

export default App; 
