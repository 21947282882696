import styled from "@emotion/styled";
import { Link } from "react-router-dom/cjs/react-router-dom";
import ROUTES from "../../constants/routes";
import LogoStlye from "../../design-system/Logo";
import { HEADER_HEIGHT } from "./constants";

const headerColor = "#FFFFFF";

const Header = () => {
  return (
    <HeaderStyle>
      <Nav>
        <LogoStlye>
          <Link to={"/"}>WEDDINGPAGE</Link>
        </LogoStlye>
        {ROUTES.map((route) => (
          <Link to={route.path} key={route.path}>
            {route.name}
          </Link>
        ))}
      </Nav>
      <Notification_tr>
        <img src="/img/notification_tr.png" alt=""></img>
        <img src="/img/Profile_tr.png" alt=""></img>
      </Notification_tr>
    </HeaderStyle>
  );
};

const HeaderStyle = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  display: flex;
  padding: 22px 15px 22px 40px;
  background-color: ${headerColor};
  color: black;
  font-weight: bold;
`;

const Nav = styled.nav`
  max-width: 652px;
  height: 100%;
  display: flex;
  align-items: center;
  & > a:hover {
    color: black;
    background-color: #0000001f;
    border-radius: 4px;
  }
  & > a {
    padding: 10px;
  }
`;

const Notification_tr = styled.div`
  margin-left: auto;
  padding: 0 100px;
  width: 24px;
  display: flex;
`;

export default Header;
