import Header from "../Header";
import Footer from "../Footer";
import { Switch, Route } from "react-router-dom/cjs/react-router-dom.min";
import ROUTES from "../../constants/routes";
import styled from "styled-components";
import { FOOTER_HEIGHT, HEADER_HEIGHT } from "../Header/constants";



const Layout = () => {
  return (
    <div>
      <Header />
      <Main>
        <Switch location={location}>
          {ROUTES.map((route) => {
            return (
              <Route exact path={route.path} key={route.path}>
                {route.component}
              </Route>
            );
          })}
        </Switch>
      </Main>
      <Footer/>
    </div>
  );
};

const Main = styled.main`
  padding-top: ${HEADER_HEIGHT}px;
  height: 100%;
  min-height: 100%;
  padding-bottom:${FOOTER_HEIGHT}px;
`;

export default Layout;
