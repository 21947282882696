import styled from "@emotion/styled";

const LogoStlye = styled.h1`
  margin-right: 50px;
  left: 40px;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  color: #000;
`;



export default LogoStlye;