import Home from "../pages/Home";
import Plan from "../pages/Plan";
import Partner from "../pages/Partner";
import Account from "../pages/Account";
import Gellery from "../pages/Gellery";
import Community from "../pages/Community";

const ROUTES = [
  {
    path: "/",
    component: Home,
    name: "홈",
    subtitle: "홈 화면",
  },
  {
    path: "/plan",
    component: Plan,
    name: "일정",
    subtitle: "일정",
  },
  {
    path: "/partner",
    component: Partner,
    name: "파트너",
    subtitle: "파트너",
  },
  {
    path: "/account",
    component: Account,
    name: "가계부",
    subtitle: "가계부",
  },
  {
    path: "/gellery",
    component: Gellery,
    name: "갤러리",
    subtitle: "갤러리",
  },
  {
    path: "/community",
    component: Community,
    name: "커뮤니티",
    subtitle: "커뮤니티",
  },
];

export default ROUTES;
