/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "styled-components";


const Plan = () => {
  return (
    <PlanStyled>
      <PlanPageHeader>어떤 플랜으로 진행할까요?</PlanPageHeader>
        <div css = {css`
          display : flex;
          justify-content : center;
        `}> 
        <div css = {css`
          display: flex;
        `}>
          <CreatePlanButtonStyle>일정 생성 1</CreatePlanButtonStyle>
          <CreatePlanButtonStyle>일정 생성 2</CreatePlanButtonStyle>
          <CreatePlanButtonStyle>일정 생성 3</CreatePlanButtonStyle>
        </div>
        </div>

        <div css = {css`
          display : flex;
          justify-content : center;
          font-family : "Noto Sans KR", sans-serif;
          font-size : 14px;
          text-align : center;
        `}>
          페이지는 최대 3개까지 생성 및 참여가 가능합니다.<br/>
          더 많은 참여를 위해서는 기존 플랜을 삭제해주세요.
        </div>
    </PlanStyled>
  );
};


const PlanStyled = styled.div`
  height: 100%;
  display : column;
  margin-top: 5rem;
`
const PlanPageHeader = styled.h1`
  text-align: center;
  font-size: x-large;
  font-weight: 700;
  font-family: "Noto Sans KR", sans-serif;
`
const CreatePlanButtonStyle = styled.div`
  margin: 10px;
  background: coral;
  height: 300px;
  width: 200px;
  border-radius: 4px;
  text-align: center;
`


export default Plan;
